import { ReloadOutlined } from "@ant-design/icons";
import {
	Alert,
	Button,
	Col,
	Form,
	Image,
	Input,
	Row,
	Typography,
	message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import Countdown from "react-countdown";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import iconDate from "../../assets/images/date.png";
import iconMap from "../../assets/images/iconMap.png";
import iconMic from "../../assets/images/iconMic.png";
import iconMoney from "../../assets/images/iconMoney.png";
import iconPhone from "../../assets/images/phoneOutline.png";
import iconTime from "../../assets/images/time.png";
import { onSetFormSearch, onSetRoom } from "../../features/checkRoomSlide";
import { bookRoom, getCaptcha } from "../../services/branch";
import "../../styles/infoOrderTime.css";
import { moneyFormat } from "../../untils/constanst";
const InfoOrderTime = (props) => {
	const { onSetCurrentStep, room } = props;
	const { formSearch } = useSelector((state) => state.rooms);
	const { branchs } = useSelector((state) => state.branchs);
	const [isCaptcha, setIsCaptcha] = useState(false);
	const [form] = Form.useForm();
	const [captchaId, setCaptchaId] = useState("");
	const [captchaImg, setCaptchaImg] = useState("");
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [isShowError, setIsShowError] = useState(false);
	useEffect(() => {
		window.scrollTo(0, 0, { behavior: "smooth" });
	}, []);
	useEffect(() => {
		if (room && room?.branch) {
			form.setFieldsValue(room);
		}
	}, []);

	const renderer = ({ minutes, seconds, completed }) => {
		if (completed) {
			message.error(
				"Đã hết thời gian đặt phòng, vui lòng đặt lại phòng của bạn.",
			);
			onSetCurrentStep(0);
		}
		return (
			<span className="time-render">
				0{minutes}:
				{seconds === 0 ? "00" : seconds < 10 ? `0${seconds}` : seconds}s
			</span>
		);
	};
	const renderer1 = ({ minutes, seconds, completed }) => {
		return (
			<span>
				0{minutes}:
				{seconds === 0 ? "00" : seconds < 10 ? `0${seconds}` : seconds}s
			</span>
		);
	};

	const onChooseOrder = () => {
		onSetCurrentStep(0);
		dispatch(onSetFormSearch({}));
		dispatch(onSetRoom({}));
	};

	const onSave = async (values) => {
		setLoading(true);
		const dataForm = {
			...formSearch,
			...values,
			time_box: Number(formSearch?.time_box),
			captcha_id: captchaId,
		};
		const data = await bookRoom(dataForm);
		if (data && data?.code === 2000) {
			setTimeout(() => {
				onSetCurrentStep(2);
				dispatch(
					onSetRoom({
						...dataForm,
						booking: data?.booking,
					}),
				);
			}, 1000);
		}
		if (data && (data?.code === 1009 || data?.code === 1010)) {
			if (data?.captcha_id) {
				setCaptchaId(data?.captcha_id);
				const dataCaptcha = await getCaptcha(data?.captcha_id);
				if (dataCaptcha) {
					const blob = new Blob([dataCaptcha]);
					const imageUrl = URL.createObjectURL(blob);
					setTimeout(() => {
						if (captchaImg) {
							setIsShowError(true);
						}
						setCaptchaImg(imageUrl);
						setIsCaptcha(true);
						form.setFieldValue("captcha", "");
					}, 1000);
				}
			}
		}
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	const branch = branchs?.find((item) => item._id === formSearch?.branch);

	const start_time = new Date(formSearch?.start_time * 1000);
	const end_time = new Date(formSearch?.end_time * 1000);
	return (
		<div>
			<div>
				<Typography.Title level={3}>Thông tin đặt phòng</Typography.Title>
				<Row gutter={[24]}>
					<Col xs={24} sm={24} md={room ? 12: 24} lg={room ? 12: 24} xl={room ? 12: 24} >
						<div className="box-time-info d-flex flex-column align-items-center justify-content-between">
							<div>
								<div className="d-flex" style={{ marginBottom: 8 }}>
									<Image className="image-icon" preview={false} src={iconMap} />
									{branch && branch?.name && (
										<Link
											to={branch?.google_map_direct_link}
											target="_blank"
											className="text-link-mobile"
										>
											<Typography.Text className="text-info fw-500">
												{branch?.name} - {branch?.address}
											</Typography.Text>
										</Link>
									)}
								</div>
								<div className="d-flex" style={{ marginBottom: 8 }}>
									<Image className="image-icon" preview={false} src={iconPhone} />
									<Typography.Text className="text-info fw-500">
										Điện thoại liên hệ: {branch?.phone ?? "096 152 1312"}
									</Typography.Text>
								</div>
								<div className="d-flex" style={{ marginBottom: 8 }}>
									<Image className="image-icon" preview={false} src={iconMic} />
									<Typography.Text className="text-info fw-500">
										{formSearch?.room === "small" &&
											`S Box (từ ${
												branch[formSearch?.room]?.capacity
											} người) - ${
												start_time.getHours() >= 18 ? "Sau 18h" : "Trước 18h"
											}`}
										{formSearch?.room === "medium" &&
											`M Box (từ ${
												branch[formSearch?.room]?.capacity
											} người) - ${
												start_time.getHours() >= 18 ? "Sau 18h" : "Trước 18h"
											}`}
										{formSearch?.room === "large" &&
											`L Box (trên ${
												branch[formSearch?.room]?.capacity
											} người) - ${
												start_time.getHours() >= 18 ? "Sau 18h" : "Trước 18h"
											}`}
									</Typography.Text>
								</div>
								<div
									className="d-flex align-items-center"
									style={{ marginBottom: 8 }}
								>
									<Image className="image-icon" preview={false} src={iconDate} />
									<Typography.Text className="text-info fw-500 d-flex align-items-center">
										{moment(start_time).format("DD/MM")} -{" "}
										<Image className="image-icon" preview={false} src={iconTime} />
										Từ {moment(start_time).format("HH:mm")} - Đến{" "}
										{moment(end_time).format("HH:mm")}
									</Typography.Text>
								</div>
								<div className="d-flex align-items-center">
									<Image className="image-icon" src={iconMoney} preview={false} />
									<Typography.Text className="text-info fw-500 d-flex align-items-center">
										Số tiền cần thanh toán: {moneyFormat(formSearch?.priceOrderDiscount)}{" "}
										VND
									</Typography.Text>
								</div>
							</div>
							{(!room || !room?.branch) && (
								<div className="top-line-dashed mt-2 pt-1 w-full text-center">
									<p className="m-0 mb-1 text-info fw-500">Thời gian còn lại</p>
									<Countdown date={Date.now() + 300000} renderer={renderer} />
								</div>
							)}
						</div>
					</Col>
					{room && <Col xs={24} sm={24} md={12} lg={12} xl={12}>
						<div
							style={{
								border: "1px solid #f0f0f0",
								borderRadius: "12px",
							}}
						>
							<iframe src={room?.google_map} 
								width="100%" height="270" style={{
									border:"0",
									borderTopRightRadius: "12px",
									borderTopLeftRadius: "12px",
								}} allowfullscreen="" 
								loading="lazy" 
								referrerpolicy="no-referrer-when-downgrade">
							</iframe>
							{room?.google_map && (
								<div
									className="d-flex align-items-center"
									style={{
										padding: "20px",
									}}
								>
									<Image src={iconMap} preview={false} />
									<div className="">
										<p
											className="m-0"
											style={{ fontSize: "16px", fontWeight: "500" }}
										>
											{room?.address}
										</p>
									</div>
								</div>
							)}
						</div>
					</Col>}
				</Row>
				<Typography.Title level={3}>Thông tin liên lạc</Typography.Title>
				<Form form={form} onFinish={onSave}>
					<Row gutter={[24]}>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Typography.Title level={5} className="mt-0 mb-1 text-info ms-0">
								Họ và tên <span className="text-red">*</span>
							</Typography.Title>
							<Form.Item
								rules={[
									{
										required: true,
										message: "Bạn phải nhập họ và tên",
									},
								]}
								name="name"
								initialValue={null}
							>
								<Input
									placeholder="Nhập họ và tên"
									className="select-list"
									size="large"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Typography.Title level={5} className="mt-0 mb-1 text-info ms-0">
								Số điện thoại <span className="text-red">*</span>
							</Typography.Title>
							<Form.Item
								rules={[
									{
										required: true,
										message: "Bạn phải nhập số điện thoại",
									},
									{
										min: 10,
										max: 13,
										pattern: new RegExp(
											"^(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})$",
										),
										message: "Vui lòng nhập đúng số điện thoại",
									},
								]}
								name="mobile"
								initialValue={null}
							>
								<Input
									placeholder="Nhập số điện thoại"
									className="select-list"
									size="large"
								/>
							</Form.Item>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Typography.Title level={5} className="mt-0 mb-1 text-info ms-0">
								Email
							</Typography.Title>
							<Form.Item
								rules={[
									{
										type: "email",
										message: "Email sai định dạng",
									},
								]}
								name="email"
								initialValue={null}
							>
								<Input
									maxLength={50}
									placeholder="Nhập email"
									className="select-list"
									size="large"
								/>
							</Form.Item>
						</Col>
						{isCaptcha && (
							<Col xs={24} sm={24} md={12} lg={12} xl={12}>
								<Typography.Title level={5} className="mt-0 mb-1">
									Mã captcha <span className="text-red">*</span>
								</Typography.Title>
								<div className="d-flex align-items-center justify-content-between">
									<Image
										src={captchaImg}
										style={{
											width: "100px",
											height: "40px",
											objectFit: "cover",
											marginRight: "5px",
										}}
										preview={false}
									/>
									<Form.Item
										rules={[
											{
												required: true,
												message: "Bạn phải nhập mã captcha",
											},
										]}
										className="m-0"
										name="captcha"
										initialValue={null}
										style={{ width: "70%" }}
									>
										<Input
											maxLength={6}
											placeholder="Nhập mã captcha bên trái"
											className="select-list"
											size="large"
										/>
									</Form.Item>
								</div>
								{isShowError && (
									<Alert
										className="mt-1 mb-1"
										message="Mã captcha không đúng. Vui lòng nhập lại!"
										type="error"
									/>
								)}
							</Col>
						)}
					</Row>
					<Row gutter={[24]}>
						<Col span={12} className="d-flex justify-content-end">
							<Button
								onClick={onChooseOrder}
								htmlType="button"
								className="btn-search-order"
							>
								Chọn phòng khác <ReloadOutlined />
							</Button>
						</Col>
						<Col span={12}>
							<Form.Item>
								<Button
									loading={loading}
									htmlType="submit"
									className="btn-search"
								>
									Đặt phòng
									<span style={{ marginLeft: "5px" }}>
										{(!room || !room?.branch) && (
											<Countdown
												date={Date.now() + 300000}
												renderer={renderer1}
											/>
										)}
									</span>
								</Button>
							</Form.Item>
						</Col>
					</Row>
				</Form>
			</div>
		</div>
	);
};

InfoOrderTime.propTypes = {};

export default InfoOrderTime;
