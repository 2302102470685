import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getBranchsApi } from "../services/branch";

export const getBranchs = createAsyncThunk("branchs/getBranchs", async () => {
  const { data } = await getBranchsApi();
  return data;
});

const branchSlide = createSlice({
  name: "branchs",
  initialState: {
    branchs: [],
    branch: {},
    loading: false,
    message: "",
    error: "",
    success: false,
    totalCount: 0,
    bookedRooms: [],
  },

  reducers: {
    onSetCampaign: (state, action) => {
      state.campaign = action.payload;
    },
    onSetBookedRoom: (state, action) => {
      state.bookedRooms = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getBranchs.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBranchs.fulfilled, (state, action) => {
      state.loading = false;
      state.branchs = action.payload;
    });
    builder.addCase(getBranchs.rejected, (state) => {
      state.message = "Get list branch fail";
      state.branchs = [];
    });
  },
});

export const { onSetCampaign, onSetBookedRoom } = branchSlide.actions;

export default branchSlide.reducer;
