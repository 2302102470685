import React, { useEffect } from "react";
import BG from "./bg.svg";

const NotFoundPage = () => {
  useEffect(() => {
    document.title = "Không tìm thấy trang";
  }, []);
  return (
    <div
      className="d-flex align-items-center justify-content-center p-8 bg-f1f1f1 overflow-x-auto position-relative h-100 w-100"
      style={{
        backgroundImage: `url(${BG})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center right",
      }}
    >
      <div className="fw-bold text-212121 text-center p-10">
        <div className="fs-96 mb-8">404</div>
      </div>
    </div>
  );
};

export default NotFoundPage;
