import React, { useEffect } from "react";
import OrderPage from "./laptop/orderPage";
import OrderPageMobile from "./mobiles/orderPageMobile";
import { useMediaQuery } from "react-responsive";

const OrderWrapper = (props) => {
	const isDesktop = useMediaQuery({
		query: "(min-width: 1224px)",
	});
	const isMobile = useMediaQuery({
		query: "(max-width: 1224px)",
	});

	useEffect(() => {
		window.scrollTo(0, 0, { behavior: "smooth" });
	}, []);

	return (
		<div>
			{isDesktop && <OrderPage />}
			{isMobile && <OrderPageMobile />}
		</div>
	);
};

OrderWrapper.propTypes = {};

export default OrderWrapper;
