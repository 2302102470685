import { Card, Col, Image, Row, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState, useCallback } from "react";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import iconTime from "../assets/images/time.png";
import Footer from "../components/footer";
import Header from "../components/header";
import { getBlogApi, getBlogsApi } from "../services/branch";
import "../styles/blog.css";
function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: "block", color: "red" }}
			onClick={onClick}
		/>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<div
			className={className}
			style={{ ...style, display: "block", color: "green" }}
			onClick={onClick}
		/>
	);
}
const settings = {
	dots: false,
	infinite: true,
	slidesToShow: 4,
	initialSlide: 0,
	loop: false,
	speed: 1000,
	cssEase: "linear",
	centerPadding: "100px",
	nextArrow: <SampleNextArrow />,
	prevArrow: <SamplePrevArrow />,
	responsive: [
		{
			breakpoint: 1024,
			settings: {
				slidesToShow: 4,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 1000,
			settings: {
				slidesToShow: 2,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 768,
			settings: {
				slidesToShow: 2,
				infinite: true,
				dots: true,
			},
		},
		{
			breakpoint: 480,
			settings: {
				slidesToShow: 1,
				infinite: true,
				dots: true,
			},
		},
	],
};
const Blog = () => {
	const [searchParams] = useSearchParams();
	const tag = searchParams.get("tag");
	const navigate = useNavigate();

	const [blog, setBlog] = useState({});
	const [blogs, setBlogs] = useState([]);
	const getDataBlog = async () => {
		const data = await getBlogApi(tag);
		if (data?.code === 2000) {
			setBlog(data?.data);
		}
	};

	useEffect(() => {
		if (!tag) return;
		getDataBlog();
	}, [tag])

	const getBlogs = async () => {
		const dataBlogs = await getBlogsApi();
		if (dataBlogs?.code === 2000) {
			setBlogs(dataBlogs?.data);
		}
	};

	useEffect(() => {
		// getDataBlog();
		getBlogs();
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0, { behavior: "smooth" });
	}, []);

	const onGetBlog = (blog_id) => {
		if (!blog_id) return;
		navigate(`/blog?tag=${blog_id}`);
	};

	return (
		<div className="bg-color">
			<Header />
			<div className="d-flex align-items-center margin-wrapper-blog">
				<Typography className="text-header">Trang chủ</Typography>
				<span style={{ margin: "0px 10px" }}>/</span>
				<Typography className="text-blog">Chi tiết blog</Typography>
			</div>
			<div className="blog-main">
				<div>
					<Typography className="text-header-content">{blog?.brief}</Typography>
					<Typography className="text-title-content">{blog?.title}</Typography>
					<Typography className="text-time-content mb-2">
						<Image src={iconTime} preview={false} />
						{moment(new Date(blog?.time * 1000)).format("DD/MM/YYYY HH:mm")}
					</Typography>
				</div>
				<div className="d-flex align-items-center justify-content-center">
					<Image
						src={blog?.image}
						preview={false}
						style={{ objectFit: "cover" }}
					/>
				</div>
				<div>
					<Typography
						className="text-blog-content"
						dangerouslySetInnerHTML={{ __html: blog?.content }}
					></Typography>
				</div>
				<div>
					<Typography className="text-blog mt-2 mb-2">
						Có thể bạn sẽ thích
					</Typography>
					<Row gutter={[24, 24]}>
						{blogs?.map((item) => (
							<Col xs={24} sm={24} md={12} lg={6} key={item.id}>
								<div className="card-item" onClick={() => onGetBlog(item?.id)}>
									<Card hoverable>
										<div className="wrapper-image-room-inner">
											<Image
												style={{
													borderTopRightRadius: 8,
													borderTopLeftRadius: 8,
													objectFit: "cover",
													height: 400,
													width: "100%",
												}}
												width={"100%"}
												src={item.image}
												preview={false}
											/>
										</div>

										<div className="wrapper-box-content">
											<ul
												style={{
													paddingLeft: 20,
													margin: "0px 0px 10px 0px",
												}}
											>
												<li className="card-item-li">
													{item.title}
												</li>
											</ul>
											<Typography className="card-text-content">
												{item.brief}
											</Typography>
										</div>
									</Card>
								</div>
							</Col>
						))}
					</Row>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default Blog;
