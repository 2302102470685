import { Modal } from "antd";

export const messageFnc = (title, type, content, onClose) => {
  switch (type) {
    case "success":
      Modal.success({
        title,
        width: 450,
        icon: null,
        content: (
          <>
            <p>{content}</p>
          </>
        ),
        okType: "default",
        okText: "Close",
        onOk: onClose
      });
      break;
    case "error":
      Modal.error({
        title,
        width: 450,
        icon: null,
        content: (
          <>
            <p>{content}</p>
          </>
        ),
        okType: "default",
        onOk: onClose,
        okText: "Close",
      });
      break;
    case "info":
      Modal.info({
        title,
        width: 450,
        icon: null,
        content: (
          <>
            <p>{content}</p>
          </>
        ),
        okType: "default",
        okText: "Close",
        onOk: onClose
      });
      break;
    default:
      return;
  }
};
