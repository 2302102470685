import http from "../http-common";
import { messageFnc } from "../components/message";

export const signIn = async (loginForm) => {
  return await http
    .post(
      "/api/login",
      loginForm,
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      messageFnc("Message", "error", "Server error");
    });
};
