
import { combineReducers } from "redux";
import authSlide from "../features/auth/authSlide";
import branchSlide from "../features/branchSlide";
import roomSlide from "../features/checkRoomSlide";

const rootReducer = combineReducers({
    auths: authSlide,
    branchs: branchSlide,
    rooms: roomSlide
});
export default rootReducer;
