import React from "react";
import "../styles/header.css";
import { Col, Image, Row, Typography } from "antd";
import imageLogo from "../images/logo.png";
import imageLogo1 from "../images/logo1.png";
import iconPhone from "../images/phone.png";
import iconEmail from "../images/email.png";
const Footer = () => {
	return (
		<Row gutter={[0, 24]} className="header footer">
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<div className="logo-1">
					<Image src={imageLogo} preview={false} />
				</div>

				<div className="logo-2">
					<Image src={imageLogo1} preview={false} />
				</div>
			</Col>
			<Col xs={24} sm={24} md={12} lg={12} xl={12}>
				<Row>
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={12}
						xl={12}
						className="d-flex align-items-center"
					>
						{/* <Image src={iconPhone} preview={false} />
						<Typography
							style={{ fontSize: "16px", fontWeight: "500", marginLeft: "5px" }}
						> */}
						{/* </Typography> */}
					</Col>
					<Col
						xs={24}
						sm={24}
						md={24}
						lg={12}
						xl={12}
						className="d-flex align-items-center mt-1"
					>
						<Image src={iconEmail} preview={false} />
						<Typography
							style={{ fontSize: "16px", fontWeight: "500", marginLeft: "5px" }}
						>
							musicbox.customers@gmail.com
						</Typography>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default Footer;
