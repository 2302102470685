/* eslint-disable jsx-a11y/iframe-has-title */
import {
	Button,
	Col,
	DatePicker,
	Form,
	Image,
	Row,
	Select,
	Typography,
	message,
} from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import iconMap from "../../assets/images/iconMap.png";
import iconMic from "../../assets/images/iconMic.png";
import iconMoney from "../../assets/images/iconMoney.png";
import NoResult from "../../assets/images/no_result.png";
import { onSetFormSearch } from "../../features/checkRoomSlide";
import { checkDiscount, checkRoom } from "../../services/branch";
import "../../styles/resultSearch.css";
import { minutes, moneyFormat, times } from "../../untils/constanst";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from 'react-tooltip'

const { Option } = Select;
const ResultSearch = (props) => {
	const { branchs } = useSelector((state) => state.branchs);
	const { formSearch } = useSelector((state) => state.rooms);
	const [form] = Form.useForm();
	const [timeOrder, setTimeOrder] = useState(null);
	// const [room, setRoom] = useState(null);
	const [timeStart, setTimeStart] = useState(null);
	const [formArray, setFormArray] = useState([]);
	const { onSetCurrentStep, room, setRoom } = props;
	const [formList] = Form.useForm();
	const dispatch = useDispatch();
	const [isShowSuggest, setIsShowSuggest] = useState(false);
	const [timeOptions, setTimeOptions] = useState(times);
	const [loading, setLoading] = useState(false);
	useEffect(() => {
		if (formSearch && formSearch?.branch) {
			const branchCheck = branchs.find(
				(item) => item._id === formSearch?.branch,
			);
			setRoom && setRoom(branchCheck);
			formSearch?.start_time &&
				setTimeOrder(
					moment(new Date(formSearch?.start_time * 1000)).format("DD/MM/YYYY"),
				);
			formSearch?.time && setTimeStart(formSearch?.time);
			formSearch?.priceOrder && setPriceOrder(formSearch?.priceOrder);
			formSearch?.priceOrderDiscount &&  setPriceOrderDiscount(formSearch?.priceOrderDiscount);
			if (formSearch?.room) {
				form.setFieldsValue({
					...formSearch,
					start_time: dayjs(
						moment(new Date(formSearch?.start_time * 1000)).format(
							"DD/MM/YYYY",
						),
						"DD/MM/YYYY",
					),
					room: `${formSearch?.room}-${
						branchCheck[formSearch?.room]?.day_price
					}`,
					time_box: `${formSearch?.time_box}`,
				});
				renderTimeStart(
					dayjs(moment(new Date()).format("DD/MM/YYYY"), "DD/MM/YYYY"),
					formSearch?.room,
				);
			} else {
				setTimeOrder(moment(new Date()).format("DD/MM/YYYY"));
				renderTimeStart(
					dayjs(moment(new Date()).format("DD/MM/YYYY"), "DD/MM/YYYY"),
					null,
				);
				form.setFieldsValue({
					...formSearch,
					start_time: dayjs(
						moment(new Date()).format("DD/MM/YYYY"),
						"DD/MM/YYYY",
					),
				});
			}
		} else {
			setTimeOrder(moment(new Date()).format("DD/MM/YYYY"));
			renderTimeStart(
				dayjs(moment(new Date()).format("DD/MM/YYYY"), "DD/MM/YYYY"),
				null,
			);
			form.setFieldsValue({
				start_time: dayjs(
					moment(new Date()).format("DD/MM/YYYY"),
					"DD/MM/YYYY",
				),
			});
		}
	}, []);

	useEffect(() => {
		window.scrollTo(0, 0, { behavior: "smooth" });
	}, []);

	const onCheckDiscount = async (valueForm)=> {
		const data = await checkDiscount(valueForm);
		if (data && data?.code === 2000) {
			let fee = data?.data ? data?.data?.fee : 0;
			let discount = data?.data ? data?.data?.discount : 0
			setPriceOrderDiscount(fee - discount);
			setPriceOrder(fee);
		} else {
			setPriceOrderDiscount(0);
			setPriceOrder(0);
		}
	}

	const onSubmit = async (values) => {
		setLoading(true);
		if (checkTimeValue()) {
			return;
		}

		if (checkTimeOut()) {
			return;
		}

		const start_time = values?.start_time ? values?.start_time?.$d : new Date();
		const hour = timeStart?.slice(0, 2);
		const minute = timeStart?.slice(3, 5);
		start_time.setHours(Number(hour));
		start_time.setMinutes(Number(minute));
		let room = "small";
		if (values?.room) {
			if (values?.room?.includes("medium")) {
				room = "medium";
			} else if (values?.room?.includes("large")) {
				room = "large";
			}
		}
		const valueForm = {
			...values,
			room: room,
			time_box: Number(
				values?.time_box === "0" ? values?.time_box_input : values?.time_box,
			),
			start_time: Math.floor(start_time.getTime() / 1000),
		};
		delete valueForm["time"];
		const data = await checkRoom(valueForm);
		if (data && data?.code === 2000) {
			onSetCurrentStep(1);
			dispatch(
				onSetFormSearch({
					...valueForm,
					time_box: values?.time_box,
					priceOrder,
					priceOrderDiscount,
					time: timeStart,
					end_time:
						Math.floor(start_time.getTime() / 1000) +
						Number(
							values?.time_box === "0"
								? values?.time_box_input
								: values?.time_box,
						) *
							60,
				}),
			);
			message.success(
				"Đã đặt lịch phòng thành công, hãy nhập thông tin để tiếp tục.",
			);
		}

		if (data && data?.code === 2101) {
			if (data?.suggest) {
				setTimeout(() => {
					setIsShowSuggest(true);
					renderFormArray(data?.suggest);
				}, 1000);
			}
		}
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	const disabledDate = (current) => {
		return current <= dayjs().add(-1, "day");
	};

	const onSetTimeStart = (value) => {
		setTimeStart(value);
		onChangeTimeOrder(value);
		checkTimeOut();
	};

	const onSetTimeOrder = (value, string) => {
		if (value) {
			setTimeOrder(string);
		}
		renderTimeStart(value, room);
		onChangeTimeOrder(value);
		const values = form.getFieldsValue();
		form.setFieldsValue({ ...values, time: null });
	};

	const onSelectRoom = (value) => {
		if (value) {
			const roomSelect = branchs.find((item) => item._id === value);
			if (roomSelect) {
				setRoom && setRoom(roomSelect);
			}
			if (!timeOrder) {
				renderTimeStart(
					dayjs(moment(new Date()).format("DD/MM/YYYY"), "DD/MM/YYYY"),
					roomSelect,
				);
			} else {
				renderTimeStart(dayjs(timeOrder, "DD/MM/YYYY"), roomSelect);
			}
		}else{
			setRoom && setRoom(null);
		}
	};

	const checkTimeValue = () => {
		const day = new Date(timeOrder).getDay();
		const month = new Date(timeOrder).getMonth();
		const year = new Date(timeOrder).getFullYear();
		const dayCurrent = new Date().getDate();
		const monthCurrent = new Date().getMonth();
		const yearCurrent = new Date().getFullYear();
		if (day === dayCurrent && month === monthCurrent && year === yearCurrent) {
			const hour = timeStart?.slice(0, 2);
			const minute = timeStart?.slice(3, 5);
			const currentHour = new Date().getHours();
			const currentMinute = new Date().getMinutes();

			if (Number(hour) === currentHour && Number(minute) < currentMinute) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	const checkTimeOut = () => {
		const valueForm = form.getFieldsValue();
		if (valueForm.time_box && valueForm?.time) {
			const hour = valueForm?.time?.slice(0, 2);
			const minute = valueForm?.time?.slice(3, 5);
			const hourStart = Number(hour) + Number(minute) / 60;
			const timeOrder = Number(valueForm.time_box);

			if (hourStart + Number(timeOrder) / 60 >= 24) {
				return true;
			} else {
				return false;
			}
		}
	};

	const [priceOrder, setPriceOrder] = useState(0);
	const [priceOrderDiscount, setPriceOrderDiscount] = useState(0);

	const onChangeTimeOrder = (value) => {
		checkTimeOut();
		const valueForm = form.getFieldsValue();

		if (
			valueForm?.time &&
			valueForm.time_box &&
			valueForm?.room &&
			valueForm.branch
		) {
			
			const hour = valueForm?.time?.slice(0, 2);
			const minute = valueForm?.time?.slice(3, 5);
			const timeOrder = Number(valueForm.time_box);
			let room = "small";
			if (valueForm?.room) {
				if (valueForm?.room?.includes("medium")) {
					room = "medium";
				} else if (valueForm?.room?.includes("large")) {
					room = "large";
				}
			}
			const branch = branchs.find((item) => item._id === valueForm.branch);
			const hourStart = Number(hour) + Number(minute) / 60;

			const start_time = valueForm?.start_time ? valueForm?.start_time?.$d : new Date();
			start_time.setHours(Number(hour));
			start_time.setMinutes(Number(minute));
			const checkDiscountForm = {
				branch: valueForm?.branch,
				room: room,
				time_box: Number(
					valueForm?.time_box === "0" ? valueForm?.time_box_input : valueForm?.time_box,
				),
				start_time: Math.floor(start_time.getTime()/ 1000),
			}
			onCheckDiscount(checkDiscountForm);

		} else {
			setPriceOrder(0);
			setPriceOrderDiscount(0);
		}
	};

	const renderTimeStart = (date, roomSelect) => {
		const day = date?.$D;
		const month = new Date(date?.$d).getMonth();
		const year = new Date(date?.$d).getFullYear();
		const dayCurrent = new Date().getDate();
		const monthCurrent = new Date().getMonth();
		const yearCurrent = new Date().getFullYear();
		if (day === dayCurrent && month === monthCurrent && year === yearCurrent) {
			const hourOrder = new Date().getHours();
			const minuteOrder = new Date().getMinutes();
			const selectOrder = hourOrder + minuteOrder / 60;
			const timeConfig = roomSelect?.open_time;
			if (timeConfig) {
				const timeConfigLst = timeConfig?.split(":");
				const startHourConfig = Number(timeConfigLst[0]);
				const startMinuteConfig = Number(timeConfigLst[1]);
				const timeHour = startHourConfig + startMinuteConfig / 60;
				setTimeOptions(
					times.filter(
						(item) => item.key > selectOrder && item.key >= timeHour,
					),
				);
			} else {
				setTimeOptions(times.filter((item) => item.key > selectOrder));
			}
		} else {
			if (roomSelect) {
				const timeConfig = roomSelect?.open_time;
				const timeConfigLst = timeConfig?.split(":");
				const startHourConfig = Number(timeConfigLst[0]);
				const startMinuteConfig = Number(timeConfigLst[1]);
				const timeHour = startHourConfig + startMinuteConfig / 60;
				setTimeOptions(times.filter((item) => item.key >= timeHour));
			} else {
				setTimeOptions(times);
			}
		}
	};

	const renderFormArray = (dataSuggest) => {
		const formArray = [];
		const room = branchs.find((value) => value._id === dataSuggest.branch);
		Object.values(dataSuggest).forEach((item) => {
			if (item?.free_time) {
				item?.free_time?.forEach((time) => {
					const itemForm = {
						...room[item?.type],
						name: room?.name,
						google_map: room?.google_map,
						address: room?.address,
						_id: room?._id,
						time_box: `${item?.time_box}`,
						room: item?.type,
						start_time: time?.start_time,
						end_time: time?.end_time,
						priceOrder: renderPriceOrder(
							time?.start_time,
							time?.end_time,
							room[item?.type]?.night_price,
							room[item?.type]?.day_price,
						)
					};
					formArray.push(itemForm);
				});
			}
		});
		setFormArray(formArray);
		formList.setFieldValue("users", formArray);
	};

	const renderPriceOrder = (start, end, night_price, day_price) => {
		let priceOrder = 0;
		const startHour = new Date(start * 1000).getHours();
		const endHour = new Date(end * 1000).getHours();
		const timeOrder = (end - start) / 60 / 60;
		if (Number(startHour) >= 18) {
			priceOrder = timeOrder * night_price;
		} else {
			if (endHour >= 18) {
				const time18 = new Date(start * 1000).setHours(18, 0, 0, 0);
				priceOrder =
					((time18 / 1000 - start) / 3600) * day_price +
					((end - time18 / 1000) / 3600) * night_price;
			} else {
				priceOrder = timeOrder * day_price;
			}
		}
		return priceOrder;
	};

	const [loadingOrder, setLoadingOrder] = useState(false);
	const [loadingKey, setLoadingKey] = useState(null);

	const onCheckRoomOrder = async (roomCheck, index) => {
		setLoadingKey(index);
		setLoadingOrder(true);
		const valueForm = {
			branch: roomCheck._id,
			room: roomCheck?.room,
			time_box: (roomCheck?.end_time - roomCheck?.start_time) / 60,
			start_time: roomCheck?.start_time,
		};
		delete valueForm["time"];
		const data = await checkRoom(valueForm);
		if (data && data?.code === 2000) {
			setTimeout(() => {
				onSetCurrentStep(1);
				dispatch(
					onSetFormSearch({
						...valueForm,
						priceOrder: roomCheck?.priceOrder,
						priceOrderDiscount: roomCheck?.priceOrderDiscount,
						time: null,
						start_time: roomCheck?.start_time,
						end_time: roomCheck?.end_time,
					}),
				);
				message.success(
					"Đã đặt lịch phòng thành công, hãy nhập thông tin để tiếp tục.",
				);
			}, 1000);
		}
		setTimeout(() => {
			setLoadingOrder(false);
		}, 1000);
	};

	const checkTime = (index) => {
		const { users } = formList.getFieldsValue();
		if (users?.length > 0) {
			const value = users[index];
			if (value?.time && value?.time_box) {
				const hour = value?.time?.slice(0, 2);
				const minute = value?.time?.slice(3, 5);
				const hourStart = Number(hour) + Number(minute) / 60;
				const timeOrder = value?.time_box;

				if (hourStart + Number(timeOrder) / 60 >= 24) {
					return true;
				} else {
					return false;
				}
			}
		}
	};

	return (
		<div>
			<Form form={form} onFinish={onSubmit}>
				<Row gutter={[24]}>
					<Col xs={24} sm={24} md={24} lg={24} xl={room ? 12: 24} >
						<div
							style={{
								border: "1px solid #f0f0f0",
								padding: "20px",
								borderRadius: "12px",
							}}
						>
							<Row gutter={[24, 24]} className="mb-2">
								<Col sx={24} sm={24} md={24} lg={24} xl={room ? 24: 12}>
									<Typography.Title
										level={5}
										className="mt-0 mb-1 text-info ms-0"
									>
										Chọn cơ sở
									</Typography.Title>
									<Form.Item
										rules={[
											{
												required: true,
												message: "Bạn phải chọn cơ sở",
											},
										]}
										name="branch"
										initialValue={null}
										className="m-0"
									>
										<Select
											className="select-list"
											size="large"
											allowClear={true}
											defaultValue={null}
											placeholder="Chọn cơ sở"
											optionLabelProp="label"
											onChange={onSelectRoom}
										>
											{branchs?.map((item) => (
												<Option
													key={item._id}
													value={item?._id}
													label={`${item.name} - ${item.address}`}
												>
													<div className="d-flex align-items-center">
														<Image src={iconMap} preview={false} />
														<div className="ps-1">
															<p className="m-0">
																{item.name}
																{item?.phone ? `: ${item?.phone}` : ""}
															</p>
															<p className="m-0">{item.address}</p>
														</div>
													</div>
												</Option>
											))}
										</Select>
									</Form.Item>
								</Col>
								<Col sx={24} sm={24} md={24} lg={24} xl={room ? 24: 12}>
									<Typography.Title
										level={5}
										className="mt-0 mb-1 text-info ms-0"
									>
										Chọn phòng
									</Typography.Title>
									<Form.Item
										rules={[
											{
												required: true,
												message: "Bạn phải chọn phòng",
											},
										]}
										name="room"
										initialValue={null}
										className="m-0"
									>
										<Select
											disabled={!room}
											optionLabelProp="label"
											className="select-list"
											size="large"
											allowClear={true}
											defaultValue={null}
											placeholder="Chọn phòng"
											onChange={onChangeTimeOrder}
										>
											{room?.small && (
												<Option
													key={`small-${room?.small?.day_price}`}
													value={`small-${room?.small?.day_price}`}
													label={`S Box (từ ${room?.small?.capacity} người)`}
												>
													<div className="d-flex align-items-center">
														<Image src={iconMic} preview={false} />
														<div className="ps-1">
															<p className="m-0">
																S Box (từ {room?.small?.capacity} người)
															</p>
														</div>
													</div>
													{/* <div className="d-flex align-items-center">
														<Image src={iconMoney} preview={false} />
														<div className="ps-1">
															<p className="m-0">
																Trước 18h: {moneyFormat(room?.small?.day_price)}
																VND/h
															</p>
														</div>
													</div>
													<div className="d-flex align-items-center">
														<Image src={iconMoney} preview={false} />
														<div className="ps-1">
															<p className="m-0">
																Sau 18h: {moneyFormat(room?.small?.night_price)}
																VND/h
															</p>
														</div>
													</div> */}
												</Option>
											)}

											{room?.medium && (
												<Option
													key={`medium-${room?.medium?.day_price}`}
													value={`medium-${room?.medium?.day_price}`}
													label={`M Box (từ ${room?.medium?.capacity} người)`}
												>
													<div className="d-flex align-items-center">
														<Image src={iconMic} preview={false} />
														<div className="ps-1">
															<p className="m-0">
																M Box (từ {room?.medium?.capacity}{" "}
																người)
															</p>
														</div>
													</div>
													{/* <div className="d-flex align-items-center">
														<Image src={iconMoney} preview={false} />
														<div className="ps-1">
															<p className="m-0">
																Trước 18h:{" "}
																{moneyFormat(room?.medium?.day_price)}
																VND/h
															</p>
														</div>
													</div>
													<div className="d-flex align-items-center">
														<Image src={iconMoney} preview={false} />
														<div className="ps-1">
															<p className="m-0">
																Sau 18h:{" "}
																{moneyFormat(room?.medium?.night_price)}
																VND/h
															</p>
														</div>
													</div> */}
												</Option>
											)}

											{room?.large && (
												<Option
													key={`large-${room?.large?.day_price}`}
													value={`large-${room?.large?.day_price}`}
													label={`L Box (trên ${room?.large?.capacity} người)`}
												>
													<div className="d-flex align-items-center">
														<Image src={iconMic} preview={false} />
														<div className="ps-1">
															<p className="m-0">
																L Box(trên {room?.large?.capacity}{" "}
																người)
															</p>
														</div>
													</div>
													{/* <div className="d-flex align-items-center">
														<Image src={iconMoney} preview={false} />
														<div className="ps-1">
															<p className="m-0">
																Trước 18h: {moneyFormat(room?.large?.day_price)}
																VND/h
															</p>
														</div>
													</div>
													<div className="d-flex align-items-center">
														<Image src={iconMoney} preview={false} />
														<div className="ps-1">
															<p className="m-0">
																Sau 18h: {moneyFormat(room?.large?.night_price)}
																VND/h
															</p>
														</div>
													</div> */}
												</Option>
											)}
										</Select>
									</Form.Item>
								</Col>
							</Row>
							<Row gutter={[24]} className="mb-2">
								<Col sx={24} sm={24} md={12} lg={12} xl={12}>
									<Typography.Title
										level={5}
										className="mt-0 mb-1 text-info ms-0"
									>
										Chọn ngày
									</Typography.Title>
									<Form.Item
										name="start_time"
										className="m-0"
										initialValue={null}
									>
										<DatePicker
											size="large"
											disabledDate={disabledDate}
											className="select-list h-38"
											defaultOpenValue={dayjs(
												moment(new Date(), "DD/MM/YYYY"),
												"DD/MM/YYYY",
											)}
											onChange={onSetTimeOrder}
											style={{ width: "100%" }}
											placeholder="Chọn ngày"
											format="DD/MM/YYYY"
										/>
									</Form.Item>
								</Col>
								<Col sx={24} sm={24} md={12} lg={12} xl={12}>
									<Typography.Title
										level={5}
										className="mt-0 mb-1 text-info ms-0"
									>
										Chọn giờ
									</Typography.Title>
									<Row className="d-flex" gutter={[12, 12]}>
										<Col span={12}>
											<Form.Item
												rules={[
													{
														required: true,
														message: "Bạn phải chọn giờ đặt phòng",
													},
												]}
												name="time"
												className="m-0"
											>
												<Select
													disabled={!timeOrder}
													className="select-list"
													size="large"
													allowClear={true}
													defaultValue={null}
													placeholder="Giờ bắt đầu"
													options={timeOptions}
													onChange={onSetTimeStart}
												/>
											</Form.Item>
										</Col>
										<Col span={12}>
											<Form.Item
												name="time_box"
												className="m-0"
												rules={[
													{
														required: true,
														message: "Bạn phải chọn thời gian",
													},
												]}
											>
												<Select
													disabled={!timeStart}
													className="select-list"
													size="large"
													allowClear={false}
													defaultValue={null}
													placeholder="Thời gian"
													options={minutes}
													onChange={onChangeTimeOrder}
												/>
											</Form.Item>
										</Col>
									</Row>

									{checkTimeOut() && (
										<span className="text-red">
											Bạn đã đặt phòng quá thời gian đóng cửa của quán là 24h
											ngày hiện tại. Vui lòng thử lại!
										</span>
									)}
								</Col>
							</Row>
						</div>
					</Col>
					{room && <Col xs={24} sm={24} md={24} lg={24} xl={12}>
						<div
							style={{
								border: "1px solid #f0f0f0",
								borderRadius: "12px",
							}}
						>
							<Image src={room?.price_image} preview={false} width={"100%"} />

							{room?.google_map && (
								<div
									className="d-flex align-items-center"
									style={{
										padding: "20px",
									}}
								>
									<Image src={iconMap} preview={false} />
									<div className="">
										<p
											className="m-0"
											style={{ fontSize: "16px", fontWeight: "500" }}
										>
											{room?.address}
										</p>
									</div>
								</div>
							)}
						</div>
					</Col>}
				</Row>
				<div className="box-wrapper-order mt-2">
					<div>
						<p className="text-order-price">Số tiền cần thanh toán
						{priceOrderDiscount > 0 && priceOrderDiscount!==priceOrder &&<span className='hint-discount' data-tooltip-id="hint-discount">?</span>}
						</p>
						<h5 className="text-order-number">
							<span className={"origin-price"+ (priceOrderDiscount > 0 && priceOrderDiscount!==priceOrder ? ' not-same' : ' same')}>{moneyFormat(priceOrder)} VND</span>
						{priceOrderDiscount > 0 && priceOrderDiscount!==priceOrder && (<span className="discount-price">{moneyFormat(priceOrderDiscount)} VND</span> )}  
						</h5>
					</div>
					<Form.Item
						className="m-0
					"
					>
						<Button loading={loading} htmlType="submit" className="btn-search">
							Đặt phòng
						</Button>
					</Form.Item>
				</div>
			</Form>
			{isShowSuggest && (
				<div>
					<div>
						<Typography.Title
							style={{
								marginBottom: "24px",
								fontSize: 24,
								borderTop: "1px solid #D0D5DD",
								paddingTop: "24px",
							}}
							level={4}
						>
							Kết quả tìm kiếm
						</Typography.Title>
						<div className="d-flex items-center justify-content-center">
							<Image src={NoResult} preview={false} />
						</div>
						<p
							style={{
								fontSize: "18px",
								textAlign: "center",
								fontWeight: "600",
								margin: "24px 0",
							}}
						>
							Xin lỗi, không tìm thấy kết quả phù hợp.
						</p>
					</div>
					<Typography.Title
						style={{
							marginBottom: "24px",
							fontSize: 24,
							borderTop: "1px dashed #D0D5DD",
							paddingTop: "24px",
						}}
						level={4}
					>
						Đề xuất kết quả
					</Typography.Title>
					<p
						style={{
							fontSize: 14,
							fontWeight: 400,
							marginBottom: 24,
							marginTop: 8,
						}}
					>
						Chúng tôi cũng có những phòng trống tại những địa điểm sau. Nếu bạn
						quan tâm, xin vui lòng chọn phòng nhé!
					</p>
					{formArray && formArray?.length > 0 && (
						<Form
							form={formList}
							name="dynamic_form_nest_item"
							autoComplete="off"
						>
							<Form.List name="users">
								{(fields, { add, remove }) => (
									<Row gutter={[24]}>
										{formArray.map((item, index) => (
											<Col xs={24} sm={24} md={12} lg={12} xl={12} key={index}>
												<div className="box-result">
													<div className="box-info">
														<Typography.Title
															style={{ marginBottom: 8, marginTop: 0 }}
															level={4}
														>
															{item?.name}
														</Typography.Title>
														<div
															className="d-flex fs-18 fw-400"
															style={{ marginBottom: 8 }}
														>
															<Image preview={false} src={iconMap} />
															<Link
																to={item?.google_map}
																target="_blank"
																className="text-link-mobile"
															>
																<Typography.Text className="hover-address text-info">
																	{item.address}
																</Typography.Text>
															</Link>
															<div className="mapouter">
																<div className="gmap_canvas">
																	<iframe
																		className="gmap_iframe"
																		frameborder="0"
																		marginheight="0"
																		marginwidth="0"
																		src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=Keang Nam &amp;t=p&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
																	></iframe>
																	<a href="https://capcuttemplate.org/">
																		Capcut Templates
																	</a>
																</div>
															</div>
														</div>
														<div
															className="d-flex align-items-center fs-18 fw-400"
															style={{ marginBottom: 8 }}
														>
															<Image preview={false} src={iconMic} />
															<Typography.Text className="text-info">
																Box (từ {item?.capacity} người)
															</Typography.Text>
														</div>
														<div
															className="d-flex align-items-center fs-18 fw-400"
															style={{ marginBottom: 8 }}
														>
															<Image preview={false} src={iconMoney} />
															<Typography.Text className="text-info">
																Trước 18h: {moneyFormat(item?.day_price)}
																VND/h
															</Typography.Text>
														</div>
														<div
															className="d-flex align-items-center fs-18 fw-400"
															style={{ marginBottom: 12 }}
														>
															<Image preview={false} src={iconMoney} />
															<Typography.Text className="text-info">
																Sau 18h: {moneyFormat(item?.night_price)}
																VND/h
															</Typography.Text>
														</div>

														<div
															style={{
																borderBottom: "1px #D8D8D8 dashed",
																paddingBottom: "18px",
															}}
														>
															<div
																style={{
																	fontSize: 18,
																	display: "flex",
																	alignItems: "center",
																}}
															>
																<Typography.Title
																	level={5}
																	className="mt-0 mb-1 text-info ms-0"
																>
																	Khung giờ:
																</Typography.Title>
																<span className="ms-1 mb-1">
																	Từ{" "}
																	{moment(item?.start_time * 1000).format(
																		"HH:mm",
																	)}{" "}
																	đến{" "}
																	{moment(item?.end_time * 1000).format(
																		"HH:mm",
																	)}
																</span>
															</div>
															{checkTime(index) && (
																<span className="text-red">
																	Bạn đã đặt phòng quá thời gian đóng cửa của
																	quán là 24h ngày hiện tại. Vui lòng thử lại!
																</span>
															)}
														</div>

														<div
															style={{
																marginTop: "18px",
																display: "flex",
																alignItems: "center",
																justifyContent: "space-between",
															}}
														>
															<div>
																<p className="text-order-price">
																	Tổng tiền thanh toán
																</p>
																<h5 className="text-order-number">
																	{moneyFormat(item.priceOrder)} VND
																</h5>
															</div>
															<Form.Item className="m-0">
																<Button
																	loading={
																		loadingOrder &&
																		item.priceOrder &&
																		loadingKey === index
																	}
																	onClick={() => onCheckRoomOrder(item, index)}
																	style={{
																		fontSize: "18px",
																		fontWeight: "500",
																		color: "#7932ED",
																		border: "1px solid #7932ED",
																		padding: "12px 24px",
																		borderRadius: "8px",
																		display: "flex",
																		justifyContent: "center",
																		alignContent: "center",
																		alignItems: "center",
																		height: "50px",
																	}}
																>
																	Đặt phòng
																</Button>
															</Form.Item>
														</div>
													</div>
												</div>
											</Col>
										))}
									</Row>
								)}
							</Form.List>
						</Form>
					)}
				</div>
			)}
      	<ReactTooltip  id='hint-discount' place="top" type="info" variant="info" effect="float"  content="Giảm giá theo chương trình khuyến mại!"/>
		</div>
	);
};

ResultSearch.propTypes = {};

export default ResultSearch;
