import http from "../http-common";
import { messageFnc } from "../components/message";
import { errorCode } from "../untils/constanst";

export const getBranchsApi = async () => {
	return await http
		.get("/getbranches")
		.then((res) => {
			if (res.data.code === 2000) {
				return res.data;
			} else {
			}
		})
		.catch((error) => {
			if (error?.response?.data) {
				messageFnc("Message", "error", errorCode[error?.response?.data?.code]);
			} else {
				messageFnc(
					"Message",
					"error",
					"Hệ thống đang gặp lỗi, xin vui lòng thử lại sau ít phút!",
				);
			}
		});
};

export const checkRoom = async (formRoom) => {
	return await http
		.post("/checkroom", formRoom)
		.then((res) => {
			if (res.data.code === 2000) {
				return res.data;
			} else {
				if (res?.data?.code && res?.data?.code !== 2101) {
					messageFnc("Message", "error", errorCode[res?.data?.code]);
				}
				return res.data;
			}
		})
		.catch((error) => {
			if (error?.response?.data) {
				messageFnc("Message", "error", errorCode[error?.response?.data?.code]);
			} else {
				messageFnc(
					"Message",
					"error",
					"Hệ thống đang gặp lỗi, xin vui lòng thử lại sau ít phút!",
				);
			}
		});
};

export const bookRoom = async (formRoom) => {
	return await http
		.post("/book_room", formRoom)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			if (error?.response?.data) {
				messageFnc("Message", "error", errorCode[error?.response?.data?.code]);
			} else {
				messageFnc(
					"Message",
					"error",
					"Hệ thống đang gặp lỗi, xin vui lòng thử lại sau ít phút!",
				);
			}
		});
};

export const checkBookedRoom = async (formRoom) => {
	return await http
		.post("/check_booked_room", formRoom)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			if (error?.response?.data) {
				messageFnc("Message", "error", errorCode[error?.response?.data?.code]);
			} else {
				messageFnc(
					"Message",
					"error",
					"Hệ thống đang gặp lỗi, xin vui lòng thử lại sau ít phút!",
				);
			}
		});
};



export const checkDiscount = async (formDiscount) => {
	return await http
		.post("/check_discount", formDiscount)
		.then((res) => {
			if (res.data.code === 2000) {
				return res.data;
			} else {
				if (res?.data?.code && res?.data?.code !== 2101) {
					messageFnc("Message", "error", errorCode[res?.data?.code]);
				}
				return res.data;
			}
		})
		.catch((error) => {
			if (error?.response?.data) {
				messageFnc("Message", "error", errorCode[error?.response?.data?.code]);
			} else {
				messageFnc(
					"Message",
					"error",
					"Hệ thống đang gặp lỗi, xin vui lòng thử lại sau ít phút!",
				);
			}
		});
};


export const getCaptcha = async (captcha_id) => {
	return await http
		.get(`/captcha?captcha_id=${captcha_id}`, { responseType: "blob" })
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			if (error?.response?.data) {
				messageFnc("Message", "error", errorCode[error?.response?.data?.code]);
			} else {
				messageFnc(
					"Message",
					"error",
					"Hệ thống đang gặp lỗi, xin vui lòng thử lại sau ít phút!",
				);
			}
		});
};

export const removeRoom = async (roomId) => {
	return await http
		.delete(`/removeroom/${roomId}`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			if (error?.response?.data) {
				messageFnc("Message", "error", errorCode[error?.response?.data?.code]);
			} else {
				messageFnc(
					"Message",
					"error",
					"Hệ thống đang gặp lỗi, xin vui lòng thử lại sau ít phút!",
				);
			}
		});
};

export const cancelRoom = async (book_id) => {
	return await http
		.post(`/cancel`, { book_id })
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			if (error?.response?.data) {
				messageFnc("Message", "error", errorCode[error?.response?.data?.code]);
			} else {
				messageFnc(
					"Message",
					"error",
					"Hệ thống đang gặp lỗi, xin vui lòng thử lại sau ít phút!",
				);
			}
		});
};

export const getBlogApi = async (blogId) => {
	return await http
		.get(`/get_post_detail`, {
			params: {
				post_id: blogId,
			},
		})
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			if (error?.response?.data) {
				messageFnc("Message", "error", errorCode[error?.response?.data?.code]);
			} else {
				messageFnc(
					"Message",
					"error",
					"Hệ thống đang gặp lỗi, xin vui lòng thử lại sau ít phút!",
				);
			}
		});
};

export const getBlogsApi = async () => {
	return await http
		.get(`/get_posts`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			if (error?.response?.data) {
				messageFnc("Message", "error", errorCode[error?.response?.data?.code]);
			} else {
				messageFnc(
					"Message",
					"error",
					"Hệ thống đang gặp lỗi, xin vui lòng thử lại sau ít phút!",
				);
			}
		});
};


export const getBlogsHome = async () => {
	return await http
		.get(`/posts_for_home`)
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			if (error?.response?.data) {
				messageFnc("Message", "error", errorCode[error?.response?.data?.code]);
			} else {
				messageFnc(
					"Message",
					"error",
					"Hệ thống đang gặp lỗi, xin vui lòng thử lại sau ít phút!",
				);
			}
		});
};

export const getQrCode = async (book_id) => {
	return await http
		.get(`/booking_qr?book_id=${book_id}`, { responseType: "blob" })
		.then((res) => {
			return res.data;
		})
		.catch((error) => {
			if (error?.response?.data) {
				messageFnc("Message", "error", errorCode[error?.response?.data?.code]);
			} else {
				messageFnc(
					"Message",
					"error",
					"Hệ thống đang gặp lỗi, xin vui liệu thử lại sau ít phút!",
				);
			}
		});
};