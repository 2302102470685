import { Button, Card, Carousel, Col, Image, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import imageBannerRight from "../images/banner1.png";
import iconMusic from "../images/iconMusic.png";
import "../styles/home.css";

import { ArrowRightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import iconAddress from "../assets/images/iconMap.png";
import iconPhone from "../assets/images/iconPhone.png";
import { getBranchs } from "../features/branchSlide";
import { onSetFormSearch } from "../features/checkRoomSlide";
import caugiay from "../images/caugiay.png";
import { getBlogsHome, getQrCode } from "../services/branch";
import { dataBanner } from "../untils/constanst";

const imageStyle = {
	maxHeight: '460px'
};
const itemStyle = {
	display: "flex",
	justifyContent: "center"
};

const Home = (props) => {
	const { branchs } = useSelector((state) => state.branchs);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [blogs, setBlogs] = useState([]);
	const [highlightBlog, setHighlightBlog] = useState({})
	const onNavigate = () => {
		dispatch(
			onSetFormSearch({
				branch: "",
			}),
		);
		navigate("/order");
	};

	const checkRoom = (branchId) => {
		dispatch(
			onSetFormSearch({
				branch: branchId,
			}),
		);
		navigate("/order");
	};

	const getBlogs = async () => {
		const dataBlogs = await getBlogsHome();
		if (dataBlogs?.code === 2000) {
			let blogs = [];
			dataBlogs?.data.forEach((blog) => {
				if (blog.is_home_highlight) {
					setHighlightBlog(blog);
				} else {
					blogs.push(blog);
				}
			})
			setBlogs(blogs);
		}
	};

	useEffect(() => {
		dispatch(getBranchs());
		getBlogs();
	}, []);

	const onGetBlog = (blog_id) => {
		if (!blog_id) return;
		navigate(`/blog?tag=${blog_id}`);
	};

	useEffect(() => {
		window.scrollTo(0, 0, { behavior: "smooth" });
	}, []);
	return (
		<div>
			<Header />
			<div className="main-home">
				<div className="banner">
					<Row className="wrapper-image align-items-center">
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<Typography className="text-muzic">
								Muzic Box <span className="text-span"></span>
							</Typography>
							<Typography className="text-banner-top">
								{dataBanner.title_one}
							</Typography>
							<Typography className="text-banner-bottom">
								{dataBanner.title_two}
							</Typography>
							{dataBanner?.contents?.map((item) => (
								<div key={item.id} className="d-flex mb-2">
									<Image src={iconMusic} preview={false}/>
									<Typography
										style={{
											fontSize: 14,
											fontWeight: "450",
											color: "#333333",
										}}
										className="text-banner-content ms-1"
									>
										{item.content}
									</Typography>
								</div>
							))}

							<Button
								onClick={onNavigate}
								className="btn-search btn-responsive"
								style={{marginTop: "30px"}}
							>
								Đặt phòng ngay
								<ArrowRightOutlined size={24}/>
							</Button>
						</Col>
						<Col
							style={{padding: "20px 0"}}
							xs={24}
							sm={24}
							md={12}
							lg={12}
							xl={12}
						>
							<Carousel className="slide-banner" autoplay>
								<div className="slide-banner-item" style={itemStyle}>
									<Image src={imageBannerRight}  style={imageStyle} preview={false}/>
								</div>
								<div className="slide-banner-item style={itemStyle}">
									<Image src={imageBannerRight}  style={imageStyle} preview={false}/>
								</div>
								<div className="slide-banner-item" style={itemStyle}>
									<Image src={imageBannerRight}  style={imageStyle} preview={false}/>
								</div>
							</Carousel>
						</Col>
					</Row>
				</div>
				<div style={{marginTop: "30px"}} className="margin-wrapper">
					<div className="text-center">
						<div className="d-flex align-items-center justify-content-center">
							<Typography className="text-muzic text-center">
								Các cơ sở <span className="text-span"></span>
							</Typography>
						</div>
						<Typography className="text-banner-bottom">
							Chúng ta có hẹn tại
						</Typography>
					</div>
					<Row gutter={[12, 12]} className="items-center">
						{branchs?.map((item, index) => (
							<Col key={item.id} xs={12} sm={12} md={12} lg={8} xl={6}>
								<Card className="card-item items-center">
									<div className="wrapper-image-room">
										<div className="wrapper-image-room-inner">
											<Image
												style={{
													borderTopRightRadius: 8,
													borderTopLeftRadius: 8,
													objectFit: "cover",
												}}
												onClick={() => checkRoom(item._id)}
												src={item.image ? item.image : caugiay}
												preview={false}
											/>
										</div>
										<div className="icon-room">
											<Image src={iconMusic} preview={false}/>
										</div>
									</div>
									<div className="wrapper-box-content">
										<Typography className="text-name text-name-home"
													onClick={() => checkRoom(item._id)}>
											{item?.name}
										</Typography>
										<div className="d-flex items-address">
											<Image
												className="icon-phone-home"
												src={iconPhone}
												preview={false}
											/>
											<a className="text-info fw-500 fs-1rem"
											   href={"tel:" + item?.phone}>{item?.phone}</a>

										</div>
										<div className="d-flex items-address">
											<Image
												className="icon-address"
												src={iconAddress}
												preview={false}
											/>

											<Link
												to={item?.google_map_direct_link}
												target="_blank"
												className="text-link-mobile"
											>
												<Typography.Text className="text-info fw-500 fs-1rem">
													{item?.address}
												</Typography.Text>
											</Link>
										</div>
										<Button
											onClick={() => checkRoom(item._id)}
											className="btn-order-home"
										>
											Đặt phòng <ArrowRightOutlined size={24}/>
										</Button>
									</div>
								</Card>
							</Col>
						))}
					</Row>
				</div>
				<div className="home-for-you">
					<Typography className="text-banner-bottom">Dành cho bạn</Typography>
					<Row gutter={[12, 24]} style={{alignContent: "end"}}>
						<Col xs={24} sm={24} md={6} lg={6} xl={6}>
							<div
								className="border-item card-item"
								onClick={() => onGetBlog(blogs[0]?.id)}
							>
								<Image
									style={{
										borderTopRightRadius: 8,
										borderTopLeftRadius: 8,
										objectFit: "cover",
									}}
									src={blogs[0]?.image}
									preview={false}
								/>
								<div className="wrapper-box-content">
									<ul style={{paddingLeft: 20, margin: "0px 0px 10px 0px"}}>
										<li className="card-item-li">{blogs[0]?.title}</li>
									</ul>
									<Typography className="card-text-content">
										{blogs[0]?.brief}
									</Typography>
								</div>
							</div>
						</Col>
						<Col xs={24} sm={24} md={12} lg={12} xl={12}>
							<div className="border-item card-item" onClick={() => onGetBlog(highlightBlog?.id)}>
								<Image
									style={{
										borderRadius: 4,
										objectFit: "cover",
										width: "100%",
										height: "100%",
									}}
									src={highlightBlog?.image}
									preview={false}
								/>
							</div>
						</Col>
						<Col xs={24} sm={24} md={6} lg={6} xl={6}
							 style={{display: "flex", alignItems: "end"}}
						>
							<Card
								className="card-item"
								onClick={() => onGetBlog(blogs[1]?.id)}
							>
								<Image
									style={{
										borderTopRightRadius: 8,
										borderTopLeftRadius: 8,
										objectFit: "cover",
									}}
									src={blogs[1]?.image}
									preview={false}
								/>
								<div className="wrapper-box-content">
									<ul style={{paddingLeft: 20, margin: "0px 0px 10px 0px"}}>
										<li className="card-item-li">{blogs[1]?.title}</li>
									</ul>
									<Typography className="card-text-content">
										{blogs[1]?.brief}
									</Typography>
								</div>
							</Card>
						</Col>
					</Row>
				</div>
			</div>
			<Footer/>
		</div>
	);
};

export default Home;
