import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Page403 from "./components/Page403";
import { HttpInterceptor } from "./http-common";
import NotFoundPage from "./pages/404";
import OrderWrapper from "./pages/orderWrapper";
import SearchOrder from "./pages/searchOrder";
import Home from "./pages/home";
import Blog from "./pages/blog";
function App() {
	return (
		<div>
			<HttpInterceptor>
				<Routes>
					<Route path="/404" element={<NotFoundPage />} />
					<Route path="/">
						<Route index element={<Navigate to={"home"} />} />
						<Route path="/home" element={<Home />} />
            			<Route path="/blog" element={<Blog />} />
						<Route path="/order" element={<OrderWrapper />} />
						<Route path="/search-order" element={<SearchOrder />} />
					</Route>
					<Route path="*" element={<Navigate to="/404" replace={true} />} />
					<Route path="/403" element={<Page403 />} />
				</Routes>
			</HttpInterceptor>
		</div>
	);
}

export default App;
