import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { checkRoom } from "../services/branch";

export const checkRoomApi = createAsyncThunk(
  "rooms/checkRoom",
  async (formRoom) => {
    const { data } = await checkRoom(formRoom);
    return data;
  }
);

export const bookRoomApi = createAsyncThunk(
  "rooms/bookRoom",
  async (formRoom) => {
    const { data } = await checkRoom(formRoom);
    return data;
  }
);

const roomSlide = createSlice({
  name: "rooms",
  initialState: {
    rooms: [],
    room: {},
    captcha_id: '',
    formSearch: {},
    loading: false,
    message: "",
    error: "",
    success: false,
    totalCount: 0,
  },

  reducers: {
    onSetFormSearch: (state, action) => {
      state.formSearch = action.payload;
    },
    onSetRoom: (state, action) => {
      state.room = action.payload;
    },

    onResetRoom: (state, action) => {
      state.room = {};
    }
  },

  extraReducers: (builder) => {
    builder.addCase(checkRoomApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkRoomApi.fulfilled, (state, action) => {
      state.loading = false;
      state.rooms = action.payload;
    });
    builder.addCase(checkRoomApi.rejected, (state) => {
      state.message = "check room fail";
      state.rooms = [];
    });
    builder.addCase(bookRoomApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(bookRoomApi.fulfilled, (state, action) => {
      state.loading = false;
      state.room = action.payload;
      state.captcha_id = action.payload.captcha_id;
    });
    builder.addCase(bookRoomApi.rejected, (state) => {
      state.message = "book room fail";
      state.room = {};
    });
  },
});

export const { onSetFormSearch, onSetRoom, onResetRoom } = roomSlide.actions;

export default roomSlide.reducer;
