import {
  Button,
  Col,
  Image,
  Row,
  Typography,
  Table,
  Modal,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import "../styles/orderResult.css";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import iconDate from "../assets/images/date.png";
import iconEmail from "../assets/images/iconEmail1.png";
import iconMap from "../assets/images/iconMap.png";
import iconMic from "../assets/images/iconMic.png";
import iconUser from "../assets/images/iconUser.png";
import phoneOutline from "../assets/images/phoneOutline.png";
import iconTime from "../assets/images/time.png";
import noData from "../assets/images/no_result.png";
import { useNavigate } from "react-router-dom";
import { getBranchs, onSetBookedRoom } from "../features/branchSlide";
import { moneyFormat } from "../untils/constanst";
import { CloseOutlined } from "@ant-design/icons";
import { cancelRoom, getQrCode } from "../services/branch";

const SearchOrder = (props) => {
  const { branchs, bookedRooms } = useSelector((state) => state.branchs);
  const [bookingId, setBookingId] = useState(null);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBranchs());
  }, []);
  const navigate = useNavigate();
  const onBack = () => {
    navigate("/order");
  };

  useEffect(() => {
    window.scrollTo(0, 0, { behavior: "smooth" });
  }, []);

  const onCancelBooking = (book_id) => {
    setBookingId(book_id);
  };

  const onCancelBookingApi = async () => {
    // start api
    setIsLoadingCancel(true);
    // viet api ở đây
    const dataCheck = await cancelRoom(bookingId);
    if (dataCheck?.code === 2000) {
      message.success("Hủy đặt phòng thành công", 3);
      setTimeout(() => {
        setIsLoadingCancel(false);
        let bookings = bookedRooms?.filter((item, index) => {
          return item?.book_id !== bookingId;
        });
        dispatch(onSetBookedRoom(bookings));
        // dispatch(onSetFormSearch({}));
        // dispatch(onSetRoom({}));
        setBookingId(null);
      }, 1000);
    } else {
      setIsLoadingCancel(false);
      message.error("Hủy đặt phòng thất bại. Vui lòng thử lại sau!", 3);
      setBookingId(null);
    }
    // end api
    setIsLoadingCancel(false);
  };

  const onCloseModal = () => {
    setBookingId(null);
    setIsLoadingCancel(false);
  };

  const handleDownload = async (booking_id) => {
    const data = await getQrCode(booking_id);
    const downloadUrl = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "QR_Pay.png"; // Tên file tải xuống
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(downloadUrl); // Hủy URL sau khi tải xong
  };

  const columns = [
    {
      title: "Cơ sở",
      dataIndex: "name",
      key: "name",
      render: (text, value) => (
        <Typography.Text>
          {branchs?.find((item) => item._id === value?.branch_id) && (
            <>
              <p className="m-0">
                {branchs?.find((item) => item._id === value?.branch_id)?.name}
              </p>
              <p className="m-0">
                {
                  branchs?.find((item) => item._id === value?.branch_id)
                    ?.address
                }
              </p>
            </>
          )}
        </Typography.Text>
      ),
    },
    {
      title: "Phòng",
      dataIndex: "room",
      key: "room",
      render: (text, value) => {
        return (
          <>
            <p className="m-0">{value?.room?.name}</p>
          </>
        );
      },
    },
    {
      title: "Thời gian hát",
      dataIndex: "time",
      key: "time",
      render: (text, value) => {
        const time = new Date(value.start_time * 1000);
        return <p className="m-0">{moment(time).format("DD/MM/YYYY HH:ss")}</p>;
      },
    },
    {
      title: "Thời lượng hát",
      dataIndex: "time_box",
      key: "time_box",
      render: (text, value) => `${text} Phút`,
    },
    {
      title: "Thành tiền",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text, value) => `${moneyFormat(value?.total_amount)} VND`,
    },
    {
      title: "Số điện thoại cơ sở",
      dataIndex: "phone",
      key: "phone",
      render: (text, value) => {
        const room = branchs.find((val) => val?._id === value.branch_id);
        return <p className="m-0">{room?.phone}</p>;
      },
    },
    {
      title: "Thao tác",
      dataIndex: "#",
      key: "#",
      render: (text, value) => {
        return (
          <div className="flex gap-1">
            <Button
              onClick={() => handleDownload(value?.book_id)}
              className="btn-pay-qr-order"
            >
              Tải Booking QR
            </Button>
            <Button
              onClick={() => onCancelBooking(value?.book_id)}
              className="btn-cancel-order ms-1"
            >
              Huỷ phòng
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <div className="bg-image">
      {bookingId && (
        <Modal
          title=""
          open={true}
          footer={null}
          closable={false}
          style={{ padding: 0 }}
        >
          <div className="d-flex align-items-center justify-content-end">
            <div onClick={onCloseModal} className="close-icon">
              <CloseOutlined />
            </div>
          </div>
          <div
            className="text-center"
            style={{ marginLeft: 64, marginRight: 64 }}
          >
            <Typography.Title level={3}>Huỷ đặt phòng</Typography.Title>
            <p className="text-center text-remove-order">
              Bạn có muốn hủy đặt phòng này không?
            </p>
            <div className="d-flex align-items-center justify-content-center">
              <Button onClick={onCloseModal} className="btn-close-cancle">
                Không
              </Button>
              <Button
                loading={isLoadingCancel}
                onClick={onCancelBookingApi}
                className="btn-close-success"
              >
                Đồng ý
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <div className="bg-search-order">
        {bookedRooms && bookedRooms?.length > 0 ? (
          <div>
            <Typography.Title
              level={2}
              className="text-center text-header-search"
            >
              Tra cứu thông tin đặt phòng
            </Typography.Title>
            <div
              style={{ padding: "10px 40px 30px 40px" }}
              className="wrapper-search-laptop"
            >
              <Typography.Text style={{ fontSize: "18px", fontWeight: "600" }}>
                Thông tin người đặt phòng
              </Typography.Text>
              <Row className="mt-2 box-wrapper">
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <div className="d-flex align-items-center mt-1 mb-1">
                    <Image
                      className="image-icon"
                      preview={false}
                      src={iconUser}
                    />
                    <Typography.Text>{bookedRooms[0]?.name}</Typography.Text>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <div className="d-flex align-items-center mt-1 mb-1">
                    <Image
                      className="image-icon"
                      preview={false}
                      src={iconEmail}
                    />
                    <Typography.Text>{bookedRooms[0]?.email}</Typography.Text>
                  </div>
                </Col>
                <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                  <div className="d-flex align-items-center mt-1 mb-1">
                    <Image
                      className="image-icon"
                      preview={false}
                      src={phoneOutline}
                    />
                    <Typography.Text>{bookedRooms[0]?.mobile}</Typography.Text>
                  </div>
                </Col>
              </Row>

              <Typography.Text
                style={{
                  fontSize: "18px",
                  fontWeight: "600",
                  margin: "20px 0px",
                  display: "block",
                }}
              >
                Danh sách các phòng đã đặt
              </Typography.Text>

              <Table columns={columns} dataSource={bookedRooms} />
            </div>
            <Row className="px-2 wrapper-search-mobile">
              {bookedRooms?.map((item, index) => {
                const room = branchs.find((val) => val?._id === item.branch_id);
                const start_time = new Date(item?.start_time * 1000);
                const end_time = new Date(item?.end_time * 1000);
                return (
                  <Col xs={24} sm={24} md={24} lg={12} xl={12} key={index}>
                    <div className="mb-2 box-wrapper">
                      <div className="border-bottom-outline">
                        <Typography.Title
                          style={{ fontSize: "18px", fontWeight: "600" }}
                          level={4}
                          className="mt-0"
                        >
                          Thông tin đặt chỗ
                        </Typography.Title>
                        <div className="d-flex mb-1">
                          <Image
                            className="image-icon"
                            preview={false}
                            src={iconMap}
                          />
                          <Typography.Text>
                            {room && room?.name && (
                              <Typography.Text>
                                {room?.name} - {room?.address}
                              </Typography.Text>
                            )}
                          </Typography.Text>
                        </div>
                        <div className="d-flex mb-1">
                          <Image
                            className="image-icon"
                            preview={false}
                            src={phoneOutline}
                          />
                          <Typography.Text>
                            Điện thoại liên hệ: {room?.phone}
                          </Typography.Text>
                        </div>
                        <div className="d-flex mb-1">
                          <Image
                            className="image-icon"
                            preview={false}
                            src={iconMic}
                          />
                          <Typography.Text className="">
                            {item?.room?.name}
                          </Typography.Text>
                        </div>
                        <div className="d-flex">
                          <Image
                            className="image-icon"
                            preview={false}
                            src={iconDate}
                          />
                          <Typography.Text>
                            {moment(start_time).format("DD/MM")} -{" "}
                            <Image
                              className="image-icon"
                              preview={false}
                              src={iconTime}
                            />
                            Từ {moment(start_time).format("HH:mm")} - Đến{" "}
                            {moment(end_time).format("HH:mm")}
                          </Typography.Text>
                        </div>
                      </div>
                      <div>
                        <Typography.Title
                          level={4}
                          style={{ fontSize: "18px", fontWeight: "600" }}
                        >
                          Thông tin liên lạc
                        </Typography.Title>
                        <div className="d-flex mb-1 align-items-center">
                          <Image
                            className="image-icon"
                            preview={false}
                            src={iconUser}
                          />
                          <Typography.Text>{item?.name}</Typography.Text>
                        </div>
                        <div className="d-flex mb-1 align-items-center">
                          <Image
                            className="image-icon"
                            preview={false}
                            src={iconEmail}
                          />
                          <Typography.Text>
                            {item?.email ?? "N/A"}
                          </Typography.Text>
                        </div>
                        <div className="d-flex mb-1 align-items-center">
                          <Image
                            className="image-icon"
                            preview={false}
                            src={phoneOutline}
                          />
                          <Typography.Text>{item?.mobile}</Typography.Text>
                        </div>
                        <div className="d-flex mb-1 mt-2 align-items-center justify-content-center">
                          <Button
                            onClick={() => handleDownload(item?.book_id)}
                            className="btn-pay-qr-order"
                          >
                            Tải Booking QR
                          </Button>
                          <Button
                            onClick={() => onCancelBooking(item?.book_id)}
                            className="btn-cancel-order ms-1"
                          >
                            Huỷ phòng
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        ) : (
          <div>
            <Typography.Title
              level={2}
              className="text-center text-header-search"
            >
              Tra cứu thông tin đặt phòng
            </Typography.Title>

            <div className="text-center mt-2 pt-2">
              <Image src={noData} preview={false} />
            </div>
            <p
              className="text-center text-header-search-content"
              style={{ fontSize: "16px", fontWeight: "600" }}
            >
              Không tìm thấy thông tin đặt phòng của bạn.{" "}
            </p>
            <p
              className="text-center text-header-search-content"
              style={{ fontSize: "16px", fontWeight: "600" }}
            >
              Vui lòng thử lại số điện thoại đặt phòng
            </p>
          </div>
        )}
        <div className="text-center d-flex justify-content-center mt-2 mb-2">
          <Button onClick={onBack} htmlType="button" className="btn-search">
            Về trang chủ
          </Button>
        </div>
      </div>
    </div>
  );
};

SearchOrder.propTypes = {};

export default SearchOrder;
