import axios from "axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { logOut } from "./features/auth/authSlide";
const accessToken = localStorage.getItem("accessToken")
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:5000/muzicapi/api/v2/",
  timeout: 10000,
  headers: {
    Accept: "application/json",
    Authorization: `Bearer ${accessToken}`,
  },
});

export const HttpInterceptor = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    // request interceptor to add accessToken to request headers
    instance.interceptors.request.use(
      async (config) => {
        if (accessToken) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          };
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const resInterceptor = (response) => {
      return response;
    };

    const errInterceptor = (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      const { status, message, statusText } = error.response || {};
      if (status === 401) {
        dispatch(logOut());
        navigate("/login", { state: { from: location } });
      }
      if (status === 408 || status === 500 || status === 502) {
        toast.error(message || statusText, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }

      return Promise.reject(error);
    };

    const interceptor = instance.interceptors.response.use(
      resInterceptor,
      errInterceptor
    );

    return () => instance.interceptors.response.eject(interceptor);
  }, [dispatch, location, navigate]);
  return children;
};

export default instance;
