import React from "react";
import "../styles/header.css";
import { Image, Typography } from "antd";
import imageLogo from "../images/logo.png";
import imageLogo1 from "../images/logo1.png";
import iconPhone from "../images/phone.png";
import iconEmail from "../images/email.png";
import { useNavigate } from "react-router-dom";
const Header = () => {
	const navigate = useNavigate();

	const onNavigate = () => {
		navigate("/");
	};
	return (
		<div className="header">
			<div className="logo-1" onClick={onNavigate}>
				<Image src={imageLogo} preview={false} />
			</div>

			<div className="logo-2">
				<Image src={imageLogo1} preview={false} />
			</div>
			<div className="wrapper-header-icon-laptop">
				{/* <div className="d-flex align-items-center">
					<Image src={iconPhone} preview={false} />
					<Typography
						style={{ fontSize: "16px", fontWeight: "500", marginLeft: "5px" }}
					>
						1900 345678
					</Typography>
				</div> */}
				<div className="d-flex align-items-center">
					<Image src={iconEmail} preview={false} />
					<Typography
						style={{ fontSize: "16px", fontWeight: "500", marginLeft: "5px" }}
					>
						musicbox.customers@gmail.com
					</Typography>
				</div>
			</div>
			<div className="wrapper-header-icon-mobile">
				<div className="d-flex align-items-center">
					<Image src={iconPhone} preview={false} alt="1900 345678" />
				</div>
				<div className="d-flex align-items-center">
					<Image
						src={iconEmail}
						preview={false}
						alt="musicbox.customers@gmail.com"
					/>
				</div>
			</div>
		</div>
	);
};

export default Header;
