import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { signIn } from "../../services/auth";

export const loginThunk = createAsyncThunk(
    "auth/loginThunk",
    async (formSearch) => {
      const data = await signIn(formSearch);
      return data;
    }
);


const authSlide = createSlice({
    name: "auths",
    initialState: {
      user: {},
      token: "",
      loading: false,
      message: "",
      error: "",
      success: false,
    },

    reducers: {
        logOut: (state, action) => {
            state.user = {};
        },
    },
  
    extraReducers: (builder) => {
      builder.addCase(loginThunk.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(loginThunk.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
      });
      builder.addCase(loginThunk.rejected, (state) => {
        state.message = "Login fail";
      });
    },
  });

  export const { logOut } = authSlide.actions;
  
  export default authSlide.reducer;