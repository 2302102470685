import { CloseOutlined } from "@ant-design/icons";
import {
	Button,
	Form,
	Image,
	Input,
	Modal,
	Steps,
	Typography,
	Alert,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import iconEmail from "../../assets/images/iconEmail.png";
import iconPhone from "../../assets/images/iconPhone.png";
import imageLogo from "../../assets/images/logo1.png";
import { getBranchs, onSetBookedRoom } from "../../features/branchSlide";
import { onSetFormSearch, onSetRoom } from "../../features/checkRoomSlide";
import "../../styles/order.css";
import { restrictNumber } from "../../untils/constanst";
import InfoOrderTimeMobile from "./infoOrderTimeMobile";
import OrderResultMobile from "./orderResultMobile";
import ResultSearchMobile from "./resultSearchMobile";
import { checkBookedRoom, getCaptcha } from "../../services/branch";
const steps = [
	{
		title: "THÔNG TIN ĐẶT PHÒNG",
		content: "First-content",
	},
	{
		title: "THÔNG TIN LIÊN LẠC",
		content: "Second-content",
	},
	{
		title: "HOÀN TẤT",
		content: "Last-content",
	},
];
const steps1 = [
	{
		title: "THÔNG TIN ĐẶT PHÒNG",
		content: "First-content",
	},
	{
		title: " ",
		content: "Second-content",
	},
	{
		title: " ",
		content: "Last-content",
	},
];
const steps2 = [
	{
		title: " ",
		content: "First-content",
	},
	{
		title: "THÔNG TIN LIÊN LẠC",
		content: "Second-content",
	},
	{
		title: " ",
		content: "Last-content",
	},
];

const steps3 = [
	{
		title: " ",
		content: "First-content",
	},
	{
		title: " ",
		content: "Second-content",
	},
	{
		title: "HOÀN TẤT",
		content: "Last-content",
	},
];
const OrderPageMobile = (props) => {
	const isMobile = useMediaQuery({
		query: "(max-width: 768px)",
	});
	const { formSearch, room } = useSelector((state) => state.rooms);
	const [current, setCurrent] = useState(0);
	const [roomSelect, setRoomSelect] = useState(null);
	const [isShowModal, setIsShowModal] = useState(false);
	const [phone, setPhone] = useState(null);
	const [isShowError, setIsShowError] = useState(false);
	const [form1] = Form.useForm();
	const [form2] = Form.useForm();
	const [isCaptcha, setIsCaptcha] = useState(false);
	const [loading, setLoading] = useState(false);
	const [capchaImg, setCapchaImg] = useState("");
	const [capchaId, setCapchaId] = useState("");
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getBranchs());
	}, [dispatch]);
	useEffect(() => {
		window.scrollTo(0, 0, { behavior: "smooth" });
	}, []);

	const onSetCurrentStep = (value) => {
		setCurrent(value);
	};

	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));

	const itemsMobile = () => {
		if (current === 1) {
			return steps2.map((item) => ({
				key: item.title,
				title: item.title,
			}));
		} else if (current === 2) {
			return steps3.map((item) => ({
				key: item.title,
				title: item.title,
			}));
		} else {
			return steps1.map((item) => ({
				key: item.title,
				title: item.title,
			}));
		}
	};

	const onShowModal = () => {
		setIsShowModal(true);
	};

	const onCloseModal = () => {
		setIsShowModal(false);
		setCapchaId(null);
		setIsCaptcha(false);
		setCapchaImg("");
		form1.resetFields();
		form2.resetFields();
	};

	const onChangeCaptcha = () => {
		setIsShowError(false);
	};

	const onSaveSearch = async (values) => {
		setLoading(true);
		const data = await checkBookedRoom({ mobile: values.phone });
		if (data && (data?.code === 1009 || data?.code === 1010)) {
			if (data?.captcha_id) {
				setCapchaId(data?.captcha_id);
				const dataCapcha = await getCaptcha(data?.captcha_id);
				if (dataCapcha) {
					const blob = new Blob([dataCapcha]);
					const imageUrl = URL.createObjectURL(blob);
					setTimeout(() => {
						setCapchaImg(imageUrl);
						setIsCaptcha(true);
						form2.resetFields();
					}, 1000);
				}
			}
		}
		if (data?.booked_room) {
			dispatch(onSetBookedRoom(data?.booked_room));
			navigate("/search-order");
		}
		setPhone(values.phone);
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	const onSaveCheckCode = async (values) => {
		setLoading(true);
		const data = await checkBookedRoom({
			mobile: phone,
			captcha_id: capchaId,
			captcha: values.captcha,
		});
		if (data?.booked_room) {
			dispatch(onSetBookedRoom(data?.booked_room));
			navigate("/search-order");
		}
		if (data && (data?.code === 1009 || data?.code === 1010)) {
			if (data?.captcha_id) {
				setCapchaId(data?.captcha_id);
				const dataCapcha = await getCaptcha(data?.captcha_id);
				if (dataCapcha) {
					const blob = new Blob([dataCapcha]);
					const imageUrl = URL.createObjectURL(blob);
					setTimeout(() => {
						setCapchaImg(imageUrl);
						setIsCaptcha(true);
						setIsShowError(true);
						form2.resetFields();
					}, 1000);
				}
			}
		}
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	};

	const onChange = (value) => {
		if (value < current) {
			setCurrent(value);
		} else {
			if (current === 0) {
				if (formSearch?.branch && !room?.branch) {
					setCurrent(1);
				}
				if (room?.branch) {
					setCurrent(value);
				}
			} else if (current === 1) {
				if (room?.branch) {
					setCurrent(value);
				}
			} else {
				setCurrent(value);
			}
		}
	};

	const onNavigateHome = () => {
		dispatch(onSetFormSearch({}));
		dispatch(onSetRoom({}));
		navigate("/");
	};

	return (
		<div>
			<Modal
				title=""
				open={isShowModal}
				footer={null}
				closable={false}
				style={{ padding: 0 }}
			>
				<div className="d-flex align-items-center justify-content-end">
					<div onClick={onCloseModal} className="close-icon">
						<CloseOutlined />
					</div>
				</div>
				<div className="text-center">
					<Typography.Title level={3}>
						Tra cứu thông tin đặt phòng
					</Typography.Title>
				</div>
				{!isCaptcha && (
					<Form form={form1} onFinish={onSaveSearch} className="p-2">
						<Typography.Title level={5} className="mb-1 mt-0">
							Số điện thoại <span className="text-red">*</span>
						</Typography.Title>
						<Form.Item
							rules={[
								{
									required: true,
									message: "Bạn phải số điện thoại để tra cứu",
								},
								{
									min: 10,
									max: 13,
									pattern: new RegExp(
										"^(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})$",
									),
									message: "Vui lòng nhập đúng số điện thoại",
								},
							]}
							name="phone"
							initialValue={null}
						>
							<Input
								onKeyPress={(e) => restrictNumber(e)}
								placeholder="Nhập số điện thoại đặt phòng"
								className="select-list"
								size="large"
							/>
						</Form.Item>
						<Form.Item className="d-flex justify-content-center">
							<Button
								loading={loading}
								htmlType="submit"
								className="btn-search"
							>
								Xác nhận
							</Button>
						</Form.Item>
					</Form>
				)}
				{isCaptcha && (
					<div>
						<Form
							form={form2}
							onFinish={onSaveCheckCode}
							style={{ marginLeft: 24, marginRight: 24 }}
						>
							<Typography.Title level={5} className="mt-0 mb-1">
								Mã captcha <span className="text-red">*</span>
							</Typography.Title>
							<div className="d-flex align-items-center justify-content-between mb-2">
								<Image
									src={capchaImg}
									style={{
										width: "100px",
										height: "40px",
										objectFit: "cover",
										marginRight: "5px",
									}}
									preview={false}
								/>
								<Form.Item
									rules={[
										{
											required: true,
											message: "Bạn phải nhập mã captcha",
										},
									]}
									className="m-0"
									name="captcha"
									initialValue={null}
									style={{ width: "70%" }}
								>
									<Input
										onChange={onChangeCaptcha}
										maxLength={6}
										placeholder="Nhập mã captcha bên trái"
										className="select-list"
										size="large"
									/>
								</Form.Item>
							</div>
							{isShowError && (
								<Alert
									className="mb-2"
									message="Mã captcha không đúng. Vui lòng nhập lại!"
									type="error"
								/>
							)}
							<Form.Item className="d-flex justify-content-center">
								<Button
									loading={loading}
									htmlType="submit"
									className="btn-search"
								>
									Xác nhận
								</Button>
							</Form.Item>
						</Form>
					</div>
				)}
			</Modal>
			<div className="wrapper-mobile">
				<div className="wrapper-mobile-header">
					<div className="d-flex align-items-center justify-content-between bg-header-mobile">
						<Image onClick={onNavigateHome} src={imageLogo} preview={false} />
						<Button onClick={onShowModal} className="btn-search-order-mobile">
							Tra cứu đặt phòng
						</Button>
					</div>
					<div className="title-mobile">
						<Typography.Title
							level={3}
							style={{ fontWeight: "700", fontSize: "24px" }}
							className="m-0"
						>
							Đặt phòng trực tuyến
						</Typography.Title>
					</div>
					<div className="wrapper-box-steps-mobile">
						<Steps
							onChange={onChange}
							current={current}
							items={isMobile ? itemsMobile() : items}
						/>
					</div>
				</div>

				<div className="order-right-mobile">
					{current === 0 && (
						<ResultSearchMobile onSetCurrentStep={onSetCurrentStep} room={roomSelect} setRoom={setRoomSelect} />
					)}
					{current === 1 && (
						<InfoOrderTimeMobile onSetCurrentStep={onSetCurrentStep} room={roomSelect} setRoom={setRoomSelect} />
					)}
					{current === 2 && (
						<OrderResultMobile onSetCurrentStep={onSetCurrentStep} />
					)}
				</div>
				<div className="bg-image-mobile">
					<div className="box-music-wrapper-mobile">
						<div className="img-logo">
							<Image src={imageLogo} preview={false} />
						</div>
						<div className="text-content">
							Muzic Box là mô hình phòng hát, thu âm đầu tiên tại Việt Nam mang
							phong cách Hàn Quốc.
						</div>
						<div className="text-content">
							Với không gian chính là các box mini dành cho các cặp đôi và nhóm,
							Muzic Box tạo cảm giác riêng tư với đồ ăn, nước giải khát và dàn
							thiết bị âm thanh, ánh sáng cực chill, hứa hẹn sẽ mang đến cho các
							bạn khoảng thời gian vui vẻ đáng nhớ. Muzic Box - "Gạt hết âu lo,
							Tự do thể hiện"
						</div>
						<div className="box-content-order">Hỗ trợ đặt phòng</div>
						{/* <div className="d-flex align-items-center mb-1">
							<div className="box-content-icon">
								<Image preview={false} src={iconPhone} />
							</div>
							<div className="box-content-text">096 152 1312</div>
						</div> */}

						<div className="d-flex align-items-center">
							<div className="box-content-icon">
								<Image preview={false} src={iconEmail} />
							</div>
							<div className="box-content-text">musicbox.customers@gmail.com</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

OrderPageMobile.propTypes = {};

export default OrderPageMobile;
